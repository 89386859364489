import SimulationTabs from 'features/simulations/SimulationTabs'
import UploadScenarioForm from 'features/simulations/UploadScenarioForm'
import StopSimulationForm from 'features/simulations/StopSimulationForm'

const domain = import.meta.env.VITE_SIMULATION_ROUTE

const SimulationsPage = () => {
  const tabs = [
    {
      id: 'upload-scenario',
      label: 'Upload Scenario',
      element: <UploadScenarioForm domain={domain} />
    },
    {
      id: 'stop-simulation',
      label: 'Stop Simulation',
      element: <StopSimulationForm domain={domain} />
    }
  ]

  return (
    <main className="flex h-full items-center justify-center p-20 pt-50">
      <SimulationTabs tabs={tabs} header="Vehicle Simulation" />
    </main>
  )
}

export default SimulationsPage
