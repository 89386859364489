import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/actions/Button'
import { useAuthenticator } from '@aws-amplify/ui-react'
import ActionBarItem, {
  ActionBarItemIcon,
  ActionBarItemList,
  ActionBarItemListItem,
  ActionBarItemListSeparator,
  ActionBarItemPopover
} from 'components/navigation/ActionBarItem'
import { useGetUserAttributesQuery, userApi } from 'services/user'

import { persistor } from 'store/setup'
import { getVehicleDetailsPageReference, resetAppState } from 'store/slices/appSlice'

const AccountMenu = () => {
  const dispatch = useDispatch()
  const { data: userAttributes } = useGetUserAttributesQuery('User')
  const { signOut } = useAuthenticator((context) => [context.user])
  const vehicleDetailsReference = useSelector(getVehicleDetailsPageReference)

  const logOut = () => {
    // close vehicle details if opened programmatically by the application
    const handle = window.open('', vehicleDetailsReference)
    handle?.close()

    setTimeout(signOut, 0)
    // clear the user state, reset the app state and purge persisted state in local storage
    dispatch(userApi.util.resetApiState())
    dispatch(resetAppState())
    persistor.purge()
  }

  if (!userAttributes) {
    return
  }

  return (
    <ActionBarItem hidePopoverOnClick={false}>
      <ActionBarItemIcon>
        <span className="icon rioglyph rioglyph-user" />
      </ActionBarItemIcon>
      <ActionBarItemPopover>
        <ActionBarItemList>
          <ActionBarItemListItem>
            <div>
              <p className="m-0 font-bold text-gray">
                {userAttributes.given_name}&nbsp;{userAttributes.family_name}
              </p>
              <p className="m-0 mt-10 text-10 text-gray">{userAttributes.email}</p>
            </div>
          </ActionBarItemListItem>
          <ActionBarItemListSeparator />
          <ActionBarItemListItem>
            <Button onClick={logOut} style="muted">
              <span className="rioglyph rioglyph-off mr-5 text-16 text-gray" />
              Logout
            </Button>
          </ActionBarItemListItem>
        </ActionBarItemList>
      </ActionBarItemPopover>
    </ActionBarItem>
  )
}

export default AccountMenu
