import { memo, useCallback } from 'react'
import { setSelectedVehicle, getSelectedVIN, clearSelectedVIN } from 'store/slices/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getVehicleIdentifier } from 'helper/vehicle'
import VehicleDeviationCard from './VehicleDeviationCard'
import { getRelativeTime } from 'helper/time'
import { useClock } from 'hooks/useClock'
import { NO_DATA } from 'helper/constants'
import { DeviationWithVIN } from 'helper/types'
import useSelectedVehicleSubscription from 'hooks/useSelectedVehicleSubscription'

export interface DeviationWithRelativeTime extends DeviationWithVIN {
  relativeTime: string
}

interface VehicleDeviationProps {
  deviation: DeviationWithVIN
  className?: string
}

const VehicleDeviation = ({ deviation, className }: VehicleDeviationProps) => {
  const dispatch = useDispatch()
  const selectedVin = useSelector(getSelectedVIN)

  const datetime = useClock()

  const { data: vehicle } = useSelectedVehicleSubscription({ vin: deviation.vin })

  const isSelected = selectedVin === vehicle?.vin
  const vehicleIdentifier = vehicle ? getVehicleIdentifier(vehicle) : NO_DATA

  const deviationWithRelativeTime: DeviationWithRelativeTime = {
    ...deviation,
    relativeTime: getRelativeTime(datetime, new Date(deviation.sentAt)) // test pw
  }

  const handleClick = useCallback(() => {
    dispatch(isSelected ? clearSelectedVIN() : setSelectedVehicle({ selectedVIN: vehicle?.vin }))
  }, [dispatch, isSelected, vehicle?.vin])

  return (
    <>
      <VehicleDeviationCard
        data={deviationWithRelativeTime}
        onClick={handleClick}
        vehicleIdentifier={vehicleIdentifier}
        isSelected={isSelected}
        className={className}
        mrmState={vehicle?.mrmState}
      />
    </>
  )
}

export default memo(VehicleDeviation)
