export interface ConfigState {
  homeRoute: string | undefined
  simulationRoute: string | undefined
  id: string | undefined
  serviceVersion: string
  serviceEnvironment: string
  enableMockServer: boolean
}

export const config: ConfigState = {
  homeRoute: import.meta.env.VITE_HOME_ROUTE,
  simulationRoute: import.meta.env.VITE_SIMULATION_ROUTE,
  id: import.meta.env.VITE_ID,
  serviceVersion: APP_VERSION,
  serviceEnvironment: import.meta.env.MODE,
  enableMockServer: import.meta.env.DEV
}
