import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { transformToRioPosition } from 'helper/position'
import { getBoundingBox, setBoundingBox } from 'store/slices/appSlice'
import { Hub, Position, Vehicle } from 'API'
import { calculateBoundingBox, getMapPosition } from '../utils'

export const useBoundingBox = (vehicles: Vehicle[], hubs?: Hub[], positions?: Position[]) => {
  const dispatch = useDispatch()
  const boundingBox = useSelector(getBoundingBox)

  const updateBoundingBox = useCallback(() => {
    const vehiclePositions = vehicles
      .filter((vehicle) => !!vehicle.position)
      .flatMap((vehicle) =>
        vehicle.position ? [transformToRioPosition(getMapPosition(vehicle))] : []
      )

    const hubPositions = hubs ? hubs.map((hub) => transformToRioPosition(hub.position)) : []
    const otherPositions = positions ? positions.map(transformToRioPosition) : []

    dispatch(
      setBoundingBox(
        calculateBoundingBox([...vehiclePositions, ...hubPositions, ...otherPositions])
      )
    )
  }, [vehicles, hubs, positions, dispatch])

  useEffect(() => {
    window.addEventListener('resize', updateBoundingBox)
    return () => {
      window.removeEventListener('resize', updateBoundingBox)
    }
  })

  useEffect(() => {
    updateBoundingBox()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles.length, hubs?.length, positions?.length])

  return { boundingBox }
}
