import { RoadSegment as RoadSegmentType, RoadSegmentStatus } from 'API'
import { transformToRioPosition } from 'helper/position'
import { useState } from 'react'
import EventUtils from 'helper/eventUtils'
import { Route, SingleMapMarker, Marker, InfoBubble } from 'components/map/Map'
import { MapApi, MapEvent } from 'components/map/types'
import RoadSegmentStatusInfo from './RoadSegmentStatusInfo'

interface RoadSegmentProps {
  data: RoadSegmentStatus
  mapApiRef?: MapApi
}

const getMiddlePosition = (waypoints: RoadSegmentType['waypoints']) => {
  return waypoints[Math.floor(waypoints.length / 2)]
}

const RoadSegment = ({ data, mapApiRef }: RoadSegmentProps) => {
  const [showRoadSegmentInfo, setShowRoadSegmentInfo] = useState(false)

  const { segment } = data

  const handleRoadSegmentClicked = () => {
    setShowRoadSegmentInfo(true)
  }

  const roadSegmentEventListenerMap = {
    [EventUtils.TAP]: (event: MapEvent) => {
      event.stopPropagation()
      handleRoadSegmentClicked()
    }
  }

  if (!segment.waypoints || segment.waypoints.length < 2) {
    return null
  }

  const markerPosition = getMiddlePosition(segment.waypoints)

  if (!segment.waypoints || segment.waypoints.length < 2) {
    return null
  }

  return (
    <>
      <Route
        positions={segment.waypoints.map(transformToRioPosition)}
        eventListenerMap={roadSegmentEventListenerMap}
        style={{
          width: 5,
          borderWidth: 2,
          isBorderIncludedInWidth: false,
          color: '#8A4FFF',
          borderColor: '#AE85FF'
        }}
        arrowStyle={{ fillColor: 'rgba(255, 255, 255, 1)', strokeColor: '#FFFFFF' }}
        hasArrows
      />
      {markerPosition && (
        <Marker
          position={transformToRioPosition(markerPosition)}
          eventListenerMap={roadSegmentEventListenerMap}
          icon={
            <SingleMapMarker
              fixed
              markerColor="bg-map-marker-restrictions"
              iconNames={['road-restrictions']}
            />
          }
        />
      )}
      {showRoadSegmentInfo && markerPosition && (
        <InfoBubble
          api={mapApiRef}
          position={transformToRioPosition(markerPosition)}
          content={<RoadSegmentStatusInfo data={data} />}
          onClose={() => {
            setShowRoadSegmentInfo(false)
          }}
        />
      )}
    </>
  )
}

export default RoadSegment
