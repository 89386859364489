import { getVehicleIdentifier } from 'helper/vehicle'
import { DrivingState, Severity, Vehicle } from 'API'
import { useMarkerStyles } from './hooks/useMarkerStyles'
import { useDispatch } from 'react-redux'
import { setSelectedVehicle } from 'store/slices/appSlice'

interface ClusterDetailsVehicleItemProps {
  vehicle: Vehicle
  isSelected: boolean
  severity?: Severity
}
const ClusterDetailsVehicleItem = ({
  vehicle,
  isSelected,
  severity
}: ClusterDetailsVehicleItemProps) => {
  const dispatch = useDispatch()

  const { classNamesWithSelection, background } = useMarkerStyles(severity, isSelected)

  const handleClick = () => {
    dispatch(setSelectedVehicle({ selectedVIN: isSelected ? undefined : vehicle.vin }))
  }

  return (
    <button
      onClick={handleClick}
      className={`${classNamesWithSelection} flex h-[30px] max-w-full items-center justify-start rounded-full text-14 leading-h2 hover:cursor-pointer`}
    >
      {vehicle.drivingState === DrivingState.DRIVING && (
        <div
          style={{
            transform: `rotate(${vehicle.position?.heading?.toFixed(0) ?? 0}deg)`
          }}
          className={`${isSelected ? background : 'bg-black/10'} -ml-2 flex aspect-square h-full items-center justify-center rounded-full `}
        >
          <span className="rioglyph rioglyph-direction ml-5 mr-4" />
        </div>
      )}
      <span className="rioglyph rioglyph-truck ml-[7px] mr-4 text-18" />
      <p className="pr-12 truncate">{getVehicleIdentifier(vehicle)}</p>
    </button>
  )
}

export default ClusterDetailsVehicleItem
