import { ElementRef, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import classnames from 'classnames'
import { Severity } from 'API'
import DeviationOrigin from './DeviationOrigin'

interface VehicleCardProps {
  severity: Severity
  description: string
  origin: string
  relativeTime: string
  vehicleIdentifier: string
  isSelected?: boolean
  className?: string
  onClick?: () => void
  icon?: React.ReactNode
}

const VehicleCard = ({
  isSelected,
  className,
  onClick,
  severity,
  origin,
  description,
  relativeTime,
  vehicleIdentifier,
  icon
}: VehicleCardProps) => {
  const elementRef = useRef<ElementRef<'div'>>(null)

  const severityClass = {
    ERROR: 'border-danger',
    WARNING: 'border-warning',
    default: 'border-info'
  }[severity || 'default']

  const linearGradient = {
    ERROR: 'linear-gradient(90deg, #FFF5F5 0%, #FFF 98.87%)',
    WARNING: 'linear-gradient(90deg, #FFF6F0 0%, #FFF 98.87%)',
    default: undefined
  }[severity || 'default']

  return (
    <AnimatePresence mode="popLayout">
      <motion.button
        initial={{
          y: -50,
          opacity: 0,
          borderRadius: 8,
          borderStyle: 'solid',
          borderWidth: 3,
          borderLeftWidth: 3
        }}
        transition={{ ease: 'easeInOut', duration: 0.5 }}
        animate={{
          y: 0,
          opacity: 1,
          borderLeftWidth: isSelected ? 75 : 3,
          backgroundImage: isSelected ? linearGradient : undefined
        }}
        exit={{ y: 50, opacity: 0 }}
        className={classnames(
          `mb-10 flex size-full bg-white pl-10 transition-shadow hover:shadow ${severityClass}`,
          className
        )}
        onClick={onClick}
      >
        <div
          ref={elementRef}
          className="relative flex size-full flex-col p-15"
          data-testid={`vehicle-deviation-card-${vehicleIdentifier.toLowerCase()}`}
        >
          {icon}
          <p className="mb-5 text-left">
            <i>{relativeTime}</i>
          </p>
          <p className="mb-0 truncate text-left font-body text-h4 font-bold">{vehicleIdentifier}</p>
          <DeviationOrigin origin={origin} severity={severity} description={description} />
        </div>
      </motion.button>
    </AnimatePresence>
  )
}

export default VehicleCard
