import { generatePath } from 'react-router'
import { ROUTES } from 'routes/types'

export const getVehicleDetailsPath = (vin?: string) => {
  return vin
    ? generatePath(`/${ROUTES.VEHICLE_BY_VIN}`, {
        vin
      })
    : `/${ROUTES.VEHICLE}`
}
export const navigateToVehicleDetails = (isOpen: boolean, vin?: string) => {
  if (!isOpen) {
    const tabId = 'vehicle-details'
    const path = getVehicleDetailsPath(vin)
    window.open(path, tabId, `name=${tabId}`)
    return tabId
  }
}
