import { DrivingState, Severity, Vehicle } from 'API'
import { SingleMapMarker } from 'components/map/Map'
import { getVehicleIdentifier } from 'helper/vehicle'
import { useMarkerStyles } from './hooks/useMarkerStyles'

interface VehicleMarkerProps {
  severity?: Severity
  vehicle: Vehicle
  isSelected: boolean
}

const VehicleMarker = ({ vehicle, severity, isSelected }: VehicleMarkerProps) => {
  const { position } = vehicle
  const vehicleIdentifier = getVehicleIdentifier(vehicle)
  const isPinging = severity !== undefined && !isSelected

  const { background } = useMarkerStyles(severity)

  if (!position) {
    return null
  }

  return (
    <SingleMapMarker
      fixed
      bearing={vehicle.position?.heading || undefined}
      moving={vehicle.drivingState === DrivingState.DRIVING}
      name={vehicleIdentifier}
      iconNames={['truck']}
      active={isSelected}
      pinging={isPinging}
      markerColor={background}
    />
  )
}

export default VehicleMarker
