import { Hub, Severity, Vehicle } from 'API'
import { useMarkerStyles } from './hooks/useMarkerStyles'
import { prioritizeDeviations } from '../prioritized-deviations/utils'
import { denormalizeListWithVin } from '../../services/utils'
import { isClusterEqual } from './utils'
import { useMemo } from 'react'
import classnames from 'classnames'

interface ClusterMarkerProps {
  vehicles: Vehicle[]
  selectedCluster: (Vehicle | Hub)[]
  hubs: Hub[]
}

const ClusterMarker = ({ vehicles, hubs, selectedCluster }: ClusterMarkerProps) => {
  const isSelected = useMemo(
    () => isClusterEqual([...vehicles, ...hubs], selectedCluster),
    [vehicles, selectedCluster, hubs]
  )

  const deviations = prioritizeDeviations(denormalizeListWithVin(vehicles))

  const hasDeviations = !!deviations?.errors.length || !!deviations?.warnings.length

  const severity = hasDeviations
    ? !!deviations?.errors?.length
      ? Severity.ERROR
      : Severity.WARNING
    : undefined

  const hubsOnly = vehicles.length === 0
  const { classNamesWithSelection, background } = useMarkerStyles(severity, isSelected, hubsOnly)
  const isPinging = severity !== undefined

  const numberItems = [...vehicles, ...hubs].length

  if (numberItems === 0) {
    // Do not render cluster marker if there are no vehicles or hubs
    // 'null' does not work here, as HERE maps will throw an error
    return <div className="hidden" />
  }

  return (
    <div className="relative aspect-square size-50 rounded-full p-10 text-14 shadow-sm hover:cursor-pointer">
      <div
        className={classnames('absolute left-0 top-0 size-full rounded-full', background, {
          'animate-pinging': isPinging
        })}
      />
      <p
        className={classnames(
          classNamesWithSelection,
          'absolute left-0 top-0 z-20 mb-4 flex size-full items-center justify-center rounded-full text-center'
        )}
      >
        {numberItems}
      </p>
    </div>
  )
}

export default ClusterMarker
