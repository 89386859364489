import containerQueriesPlugin from '@tailwindcss/container-queries'

/** @type {import('tailwindcss').Config} */
export default {
  important: true,
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx,mdx}'],
  /* the default tailwind class "fixed" has an overlap with a RIO UIKit class and breaking the MapMarker styles.
  As a solution, we are using "tw-fixed" instead of "fixed", see @utilities in index.css
  */
  blocklist: ['fixed'],
  theme: {
    fontFamily: {
      sans: ['Source Sans Pro', 'Trebuchet MS', 'Helvetica', 'Arial', 'sans-serif'],
      body: ['TRATON Type Text', 'Trebuchet MS', 'Helvetica', 'Arial', 'sans-serif'],
      display: ['TRATON Type Display', 'Trebuchet MS', 'Helvetica', 'Arial', 'sans-serif']
    },
    spacing: {
      0: '0px',
      1: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
      5: '5px',
      10: '10px',
      12: '12px',
      15: '15px',
      20: '20px',
      25: '25px',
      40: '40px',
      50: '50px',
      75: '75px',
      100: '100px'
    },
    fontSize: {
      h1: '40px',
      h2: '30px',
      h3: '26px',
      h4: '22px',
      h5: '18px',
      h6: '14px',
      10: '10px',
      11: '11px',
      12: '12px',
      14: '14px',
      16: '16px',
      18: '18px',
      20: '20px'
    },
    lineHeight: {
      h1: '40px',
      h2: '30px',
      h3: '26px',
      h4: '22px',
      h5: '18px',
      h6: '14px',
      10: '10px',
      11: '11px',
      12: '12px',
      14: '14px',
      16: '16px',
      18: '18px',
      20: '20px',
      24: '24px',
      '125rel': '1.25',
      '130rel': '1.30',
      '150rel': '1.50'
    },
    colors: {
      current: 'current',
      inherit: 'inherit',
      white: '#FFFFFF',
      black: '#000000',
      primary: '#328092',
      secondary: '#D22B40',
      info: '#4B96D2',
      success: '#71B564',
      warning: '#FF8E3C',
      danger: '#e22837',
      restrictions: '#8A4FFF',
      transparent: 'rgba(0, 0, 0, 0)',
      gray: {
        decent: '#FDFDFD',
        lightest: '#F6F8F9',
        lighter: '#E5EBF0',
        light: '#D0D8DE',
        DEFAULT: '#A7AFBB',
        dark: '#697A8B',
        darker: '#4C5667',
        darkest: '#2A3740'
      },
      highlight: {
        decent: '#FFFFFF',
        lightest: '#F9FCFD',
        lighter: '#D3EBF0',
        light: '#9AD0DC',
        DEFAULT: '#328092',
        dark: '#255F6C',
        darker: '#183D46',
        darkest: '#0B1C20'
      },
      status: {
        available: '#239B7D',
        driving: '#3690AE',
        resting: '#626B72',
        working: '#94488B'
      },
      'map-marker': {
        asset: '#2A3740',
        poi: '#4B96D2',
        geofence: '#F9636E',
        route: '#36AFD7',
        warning: '#FF8E3C',
        error: '#e22837',
        restrictions: '#8A4FFF'
      },
      rating: {
        1: '#D90000',
        2: '#FF8E3C',
        3: '#F8C575',
        4: '#5CB85C',
        5: '#4B924C'
      }
    },
    boxShadow: {
      none: 'none',
      DEFAULT: `0 5px 1px 1px hsla(0,0%,0%,.01),
        0 5px 2px 2px hsla(0,0%,0%,.01),
        0 5px 3px 3px hsla(0,0%,0%,.01),
        0 5px 4px 4px hsla(0,0%,0%,.01),
        0 10px 5px 5px hsla(0,0%,0%,.01)`,
      lg: `0 4px 4px 0px hsla(0, 0%, 0%, 0.08),
        0 4px 20px 0px hsla(0, 0%, 0%, 0.08)`,
      sm: `0 0 2px 2px hsla(0,0%,0%,.01),
        0 0 4px 4px  hsla(0,0%,0%,.01),
        0 0 6px 6px hsla(0,0%,0%,.01),
        0 0 8px 8px hsla(0,0%,0%,.01),
        0 0 10px 10px hsla(0,0%,0%,.01)`,
      top: '0px -4px 20px 0px #00000014'
    },
    extend: {
      keyframes: {
        move: {
          '0%': { transform: 'translate(0, -50px)' },
          '100%': { transform: 'translate(0, 0)' }
        },
        pinging: {
          '75%,to': {
            transform: 'scale(1.5)',
            opacity: 0
          }
        }
      },
      animation: {
        'translate-down': 'move 0.2s linear',
        pinging: 'pinging 1s cubic-bezier(0, 0, 0.2, 1) infinite'
      }
    }
  },
  plugins: [containerQueriesPlugin]
}
