import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIsVehicleDetailsOpen,
  getSelectedVIN,
  setVehicleDetailsPageReference
} from 'store/slices/appSlice'
import useSelectedVehicleSubscription from './useSelectedVehicleSubscription'
import { navigateToVehicleDetails } from './utils'

const useMultimonitorSetup = () => {
  const selectedVIN = useSelector(getSelectedVIN)
  const dispatch = useDispatch()
  const isVehicleDetailPageOpen = useSelector(getIsVehicleDetailsOpen)

  const { data: vehicleData } = useSelectedVehicleSubscription()

  useEffect(() => {
    const tabId = navigateToVehicleDetails(isVehicleDetailPageOpen, selectedVIN)
    dispatch(setVehicleDetailsPageReference(tabId))
  }, [isVehicleDetailPageOpen, selectedVIN, vehicleData?.activeMissionId, dispatch])
}

export default useMultimonitorSetup
