interface FilePreviewProps {
  file?: File
  content?: string
}

const FilePreview = ({ file, content }: FilePreviewProps) => {
  return (
    <div
      className={`mt-15 h-[300px] w-full overflow-y-auto bg-gray-lighter p-20 ${!file && 'flex items-center justify-center'}`}
    >
      <div className="">{content ?? 'No file to preview'}</div>
    </div>
  )
}

export default FilePreview