import { ElementRef, useRef } from 'react'
import { Severity } from 'API'
import classnames from 'classnames'
import SeverityIcon from './SeverityIcon'
import { assertConnectivityDeviation } from 'helper/assertDeviation'

interface DeviationOriginProps {
  severity: Severity
  origin: string
  description: string
}

const DeviationOrigin = ({ origin, severity, description }: DeviationOriginProps) => {
  const defaultOffSetWidth = 350
  const iconOffSet = 20
  const elementRef = useRef<ElementRef<'div'>>(null)
  const descriptionOffsetWidthClass = `w-[${(elementRef.current?.offsetWidth || defaultOffSetWidth) - iconOffSet}]`

  const deviationIcon = assertConnectivityDeviation(origin) ? (
    <span
      className={classnames('rioglyph rioglyph-wifi-off mr-10 text-20', {
        'text-warning': severity === Severity.WARNING,
        'text-danger': severity === Severity.ERROR
      })}
    />
  ) : (
    <SeverityIcon severity={severity} width={iconOffSet} height={iconOffSet} />
  )

  return (
    <div className="mt-5 flex items-center">
      <div className="size-20">{deviationIcon}</div>
      <p className={classnames(descriptionOffsetWidthClass, 'my-0 ml-10 truncate text-left')}>
        {description}
      </p>
    </div>
  )
}

export default DeviationOrigin
