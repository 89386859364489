import { Hub } from 'API'

interface ClusterDetailsHubItemProps {
  hub: Hub
}
const ClusterDetailsHubItem = ({ hub }: ClusterDetailsHubItemProps) => {
  return (
    <div className="pr-12 flex h-[30px] max-w-full items-center justify-start rounded-full bg-map-marker-poi pl-5 text-14 hover:cursor-pointer">
      <span className="rioglyph rioglyph-factory ml-[6px] mr-4 text-18" />
      <p className="truncate">{hub.name}</p>
    </div>
  )
}

export default ClusterDetailsHubItem
