import { ReactNode, useState } from 'react'
import TabLink from './TabLink'

interface Tab {
  id: string
  label: string
  element: ReactNode
}

interface SimulationTabsProps {
  header?: string
  tabs: Tab[]
}

/**
 * Another approach over RIO Tabs, without routing.
 * Move into /components - if needed for reuse.
 */
const SimulationTabs = ({ tabs, header }: SimulationTabsProps) => {
  const [activeTab, handleActiveTab] = useState<string | undefined>(tabs[0]?.id)
  const labels = tabs.map((_) => ({ id: _.id, label: _.label }))
  const contents = tabs.map((_) => ({ id: _.id, element: _.element }))

  return (
    <div className="h-[540px] w-[700px] bg-white shadow-sm">
      {header && (
        <p
          data-testid="simulation-tabs-header"
          className="w-full border-4 border-white bg-info px-10 py-2"
        >
          {header}
        </p>
      )}
      <ul className="flex gap-15 px-20 py-15">
        {labels.map((_) => (
          <li key={_.id}>
            <TabLink
              id={_.id}
              onClick={() => handleActiveTab(_.id)}
              label={_.label}
              active={activeTab === _.id}
            />
          </li>
        ))}
      </ul>
      <div data-testid="simulation-tabs-content" className="px-20 pb-15">
        {contents.find((_) => _.id === activeTab)?.element}
      </div>
    </div>
  )
}

export default SimulationTabs
