interface TabLinkProps {
  id: string
  label: string
  active?: boolean
  onClick(): void
}

const TabLink = ({ id, onClick, label, active }: TabLinkProps) => {
  return (
    <p
      data-testid={`tab-link-${id}`}
      className={`border-b-4 text-20 text-black ${active ? 'pointer-events-none cursor-auto border-black font-bold' : 'cursor-pointer border-white'}`}
      onClick={onClick}
    >
      {label}
    </p>
  )
}

export default TabLink
