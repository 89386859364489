import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { HealthStatus } from 'API'
import { useGetHealthStatusQuery } from 'services/systemHealth'
import { setIsSystemOK } from 'store/slices/appSlice'

export const useCheckHealthStatus = () => {
  const dispatch = useDispatch()
  /*
		Initialize status as OK assuming the system is working when the request is triggered.
		This prevents the SystemNotification being rendered at the start.
	*/
  const { data: healthStatus = { status: HealthStatus.OK }, isError: systemHealthError } =
    useGetHealthStatusQuery('GetHealthStatus', { pollingInterval: 5_000 })

  useEffect(() => {
    dispatch(setIsSystemOK(healthStatus?.status === HealthStatus.OK && !systemHealthError))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthStatus, systemHealthError])
}
