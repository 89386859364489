import { MarkerLayer } from 'components/map/Map'
import { MapApi, MapEvent, Point } from 'components/map/types'
import EventUtils from 'helper/eventUtils'
import { useCallback, useMemo } from 'react'
import { DeviationWithVIN } from 'helper/types'
import VehicleMarker from './VehicleMarker'
import { getSeverityLevel, isClusterEqual, isHub, isVehicle } from './utils'
import { Hub, Vehicle } from 'API'
import { transformToRioPosition } from 'helper/position'
import { useDispatch, useSelector } from 'react-redux'
import HubMarker from './HubMarker'
import {
  clearSelectedVIN,
  getSelectedCluster,
  getSelectedVIN,
  setSelectedCluster,
  setSelectedVehicle
} from 'store/slices/appSlice'
import ClusterMarker from './ClusterMarker'

interface ClusterMarkerLayerProps {
  vehicles: Vehicle[]
  hubs?: Hub[]
  deviations?: DeviationWithVIN[]
  mapApi: MapApi
  showHubs: boolean
}

const ClusterMarkerLayer = ({ vehicles, deviations, hubs, showHubs }: ClusterMarkerLayerProps) => {
  const dispatch = useDispatch()
  const selectedVIN = useSelector(getSelectedVIN)
  const selectedCluster = useSelector(getSelectedCluster)

  const vehiclesWithPosition = useMemo(
    () =>
      vehicles
        .map((vehicle) => {
          const position = vehicle.position ? transformToRioPosition(vehicle.position) : {}
          return {
            ...vehicle,
            ...position
          }
        })
        .filter((v) => v.position),
    [vehicles]
  )

  const hubsWithPosition = useMemo(
    () => hubs?.map((hub) => ({ ...hub, ...transformToRioPosition(hub.position) })),
    [hubs]
  )

  const vehiclesAndHubs = useMemo(
    () => [...vehiclesWithPosition, ...(showHubs && hubsWithPosition ? hubsWithPosition : [])],
    [vehiclesWithPosition, hubsWithPosition, showHubs]
  )

  const handleVehicleMarkerClick = useCallback(
    (vin: string) => {
      const isSelected = vin === selectedVIN
      if (isSelected) {
        dispatch(clearSelectedVIN())
        return
      }

      dispatch(setSelectedVehicle({ selectedVIN: vin }))
    },
    [dispatch, selectedVIN]
  )

  const theme = useMemo(
    () => ({
      getNoisePresentation: (item: Vehicle | Hub) =>
        isVehicle(item) ? (
          <VehicleMarker
            isSelected={item.vin === selectedVIN}
            vehicle={item}
            severity={getSeverityLevel(item.vin, deviations)}
          />
        ) : (
          <HubMarker hub={item} />
        ),
      getClusterPresentation: (clusterData: (Vehicle | Hub)[]) => (
        <ClusterMarker
          selectedCluster={selectedCluster}
          vehicles={clusterData.filter(isVehicle)}
          hubs={clusterData.filter(isHub)}
        />
      )
    }),
    [deviations, selectedVIN, selectedCluster]
  )

  const handleClusterEvent = (event: MapEvent) => {
    const targetData = event.target.getData()
    event.stopPropagation()

    if (targetData.isCluster()) {
      const data: (Vehicle | Hub)[] = []
      targetData.forEachDataPoint((point: Point) => data.push(point.getData()))

      const isClusterSelected = isClusterEqual(selectedCluster, data)

      dispatch(setSelectedCluster(isClusterSelected ? [] : data))
    } else {
      handleVehicleMarkerClick(targetData.getData().vin)
    }
  }

  const eventListenerMap = EventUtils.createTapOnDblTapPreventer({
    [EventUtils.TAP]: handleClusterEvent
  })

  return (
    <MarkerLayer data={vehiclesAndHubs} simpleTheme={theme} eventListenerMap={eventListenerMap} />
  )
}

export default ClusterMarkerLayer
