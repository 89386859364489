import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react'
import { amplifyTheme } from 'theme'
import { config } from './config'
import { main } from './configuration'
import { store, persistor, purgeIfVersionMismatch } from './store/setup'
import ErrorBoundary from 'components/ErrorBoundary'
import { components, formFields } from 'components/forms/AuthComponents'
import '@aws-amplify/ui-react/styles.css'
import { Amplify } from 'aws-amplify'
import { authConfig } from 'amplify/config'
import { z } from 'zod'
import { NonEmptyString, OptionalNonEmptyString } from 'helper/zod'
import { PersistGate } from 'redux-persist/integration/react'
import { useEffect } from 'react'
import ErrorFallback from 'components/feedback/ErrorFallback'
import { router } from 'routes'
import * as Logger from 'services/logger'

Amplify.configure(authConfig)

const isDev = import.meta.env.DEV
const isProd = import.meta.env.PROD

const EnvSchema = () =>
  z
    .object({
      DEV_SERVER_PORT: OptionalNonEmptyString(),
      PORT: OptionalNonEmptyString(),
      VITE_BUILD_VERSION: OptionalNonEmptyString(),
      VITE_AUTH_IDENTITY_POOL_ID: NonEmptyString(),
      VITE_AUTH_USER_POOL_CLIENT_ID: NonEmptyString(),
      VITE_AUTH_USER_POOL_ID: NonEmptyString(),
      VITE_ENABLE_MOCK_SERVER: OptionalNonEmptyString(),
      VITE_GRAPHQL_ENDPOINT: NonEmptyString(),
      VITE_HOME_ROUTE: NonEmptyString(),
      VITE_SIMULATION_ROUTE: NonEmptyString(),
      VITE_ID: NonEmptyString(),
      VITE_TEAM_IDENTIFIER: NonEmptyString(),
      VITE_ACCOUNT_ID: NonEmptyString(),
      VITE_MAP_SERVICE_API_KEY: NonEmptyString(),
      VITE_HERE_REVERSE_GEOCODE_BASE_URL: NonEmptyString(),
      VITE_DATADOG_SITE: NonEmptyString(),
      VITE_DATADOG_CLIENT_TOKEN: NonEmptyString()
    })
    .passthrough()

EnvSchema().parse(import.meta.env)

console.info(`[CC] Build Version ${import.meta.env.VITE_BUILD_VERSION}`)

declare global {
  interface ImportMetaEnv extends z.infer<ReturnType<typeof EnvSchema>> {
    BASE_URL: string
    MODE: string
    DEV: boolean
    PROD: boolean
    SSR: boolean
  }
}

const App = () => {
  useEffect(purgeIfVersionMismatch, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  )
}

const renderApplication = () => {
  const rootContainer = document.querySelector('#root')
  if (!rootContainer) {
    throw new Error('Root container missing in index.html')
  }

  const root = createRoot(rootContainer)
  root.render(
    <ThemeProvider theme={amplifyTheme}>
      <Authenticator components={components} formFields={formFields} hideSignUp>
        <App />
      </Authenticator>
    </ThemeProvider>
  )
  Logger.logPageViews('Control Center', Logger.getSessionID())
}

if (isDev && config.enableMockServer) {
  import('../mocks/browser')
    .then(({ startWorker }) => startWorker())
    .then(() => main(renderApplication))
}

if (isProd) {
  main(renderApplication)
}
