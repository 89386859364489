/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY DIRECTLY.
 */
import { z } from 'zod'
import { AdsState, ArchiveMissionInput, AssignMissionInput, Camera, CameraImage, CameraInput, CancelMissionInput, Command, CommandResponse, ConnectivityState, ConnectivityStatus, ConnectivityStatusInput, ContinueStreamInput, ContinueVideoStream, CreateHubInput, Deviation, DeviationInput, DrivingState, ExceptionRequestInput, ExceptionRequestStatus, ExceptionResponse, ExceptionSegment, ExceptionSegmentInput, GeneratedRoute, HealthStatus, HealthStatusInformation, Hub, MRMState, MRMStateInput, MRMStateValue, Mission, MissionEvent, MissionEventInput, MissionEventType, MissionProgressDetails, MissionProgressDetailsInput, MissionState, MissionStateInput, MissionStateValue, Owner, OwnerInput, Position, PositionInput, Reason, ResetMissionInput, RoadSegment, RoadSegmentDirection, RoadSegmentInput, RoadSegmentRestrictionCause, RoadSegmentStatus, Route, Severity, UpdateHubInput, UpdatedGeneratedRouteInput, UpdatedMissionInput, UpdatedRoadSegmentStatusInput, UpdatedVehicleInput, UpdatedVideoStreamingErrorInput, Vehicle, VehicleCommandInput, VideoStream, VideoStreamingError, VideoStreamingErrorCode } from './API'

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny => v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export const AdsStateSchema = z.nativeEnum(AdsState);

export const CommandSchema = z.nativeEnum(Command);

export const ConnectivityStateSchema = z.nativeEnum(ConnectivityState);

export const DrivingStateSchema = z.nativeEnum(DrivingState);

export const ExceptionRequestStatusSchema = z.nativeEnum(ExceptionRequestStatus);

export const HealthStatusSchema = z.nativeEnum(HealthStatus);

export const MRMStateValueSchema = z.nativeEnum(MRMStateValue);

export const MissionEventTypeSchema = z.nativeEnum(MissionEventType);

export const MissionStateValueSchema = z.nativeEnum(MissionStateValue);

export const ReasonSchema = z.nativeEnum(Reason);

export const RoadSegmentDirectionSchema = z.nativeEnum(RoadSegmentDirection);

export const RoadSegmentRestrictionCauseSchema = z.nativeEnum(RoadSegmentRestrictionCause);

export const SeveritySchema = z.nativeEnum(Severity);

export const VideoStreamingErrorCodeSchema = z.nativeEnum(VideoStreamingErrorCode);

export function ArchiveMissionInputSchema(): z.ZodObject<Properties<ArchiveMissionInput>> {
  return z.object({
    missionId: z.string().min(1)
  })
}

export function AssignMissionInputSchema(): z.ZodObject<Properties<AssignMissionInput>> {
  return z.object({
    predefinedRouteId: z.string().min(1),
    vin: z.string().min(1)
  })
}

export function CameraSchema(): z.ZodObject<Properties<Camera>> {
  return z.object({
    __typename: z.literal('Camera').optional(),
    id: z.string().nullish(),
    name: z.string().min(1)
  })
}

export function CameraImageSchema(): z.ZodObject<Properties<CameraImage>> {
  return z.object({
    __typename: z.literal('CameraImage').optional(),
    timestamp: z.string().datetime(),
    value: z.string().min(1)
  })
}

export function CameraInputSchema(): z.ZodObject<Properties<CameraInput>> {
  return z.object({
    id: z.string().nullish(),
    name: z.string().min(1)
  })
}

export function CancelMissionInputSchema(): z.ZodObject<Properties<CancelMissionInput>> {
  return z.object({
    missionId: z.string().min(1)
  })
}

export function CommandResponseSchema(): z.ZodObject<Properties<CommandResponse>> {
  return z.object({
    __typename: z.literal('CommandResponse').optional(),
    command: CommandSchema,
    errorMessage: z.string().nullish(),
    invocationId: z.string().uuid().min(1),
    isSuccess: z.boolean(),
    receivedByVehicleAt: z.string().datetime(),
    sentToVehicleAt: z.string().datetime(),
    vin: z.string().min(1)
  })
}

export function ConnectivityStatusSchema(): z.ZodObject<Properties<ConnectivityStatus>> {
  return z.object({
    __typename: z.literal('ConnectivityStatus').optional(),
    description: z.string().nullish(),
    expectedDisconnect: z.boolean().nullish(),
    latency: z.number().nullish(),
    reason: ReasonSchema.nullish(),
    state: ConnectivityStateSchema.nullish()
  })
}

export function ConnectivityStatusInputSchema(): z.ZodObject<Properties<ConnectivityStatusInput>> {
  return z.object({
    description: z.string().nullish(),
    error: SeveritySchema.nullish(),
    latency: z.number().nullish(),
    reason: ReasonSchema.nullish(),
    state: ConnectivityStateSchema.nullish()
  })
}

export function ContinueStreamInputSchema(): z.ZodObject<Properties<ContinueStreamInput>> {
  return z.object({
    cameraID: z.string().nullish(),
    vin: z.string().min(1)
  })
}

export function ContinueVideoStreamSchema(): z.ZodObject<Properties<ContinueVideoStream>> {
  return z.object({
    __typename: z.literal('ContinueVideoStream').optional(),
    cameraID: z.string().nullish(),
    vin: z.string().min(1)
  })
}

export function CreateHubInputSchema(): z.ZodObject<Properties<CreateHubInput>> {
  return z.object({
    active: z.boolean(),
    name: z.string().min(1),
    position: z.lazy(() => PositionInputSchema())
  })
}

export function DeviationSchema(): z.ZodObject<Properties<Deviation>> {
  return z.object({
    __typename: z.literal('Deviation').optional(),
    category: z.string().min(1),
    description: z.string().min(1),
    id: z.string().uuid().min(1),
    origin: z.string().min(1),
    sentAt: z.string().datetime(),
    severity: SeveritySchema
  })
}

export function DeviationInputSchema(): z.ZodObject<Properties<DeviationInput>> {
  return z.object({
    category: z.string().min(1),
    description: z.string().min(1),
    id: z.string().uuid().min(1),
    origin: z.string().min(1),
    sentAt: z.string().datetime(),
    severity: SeveritySchema
  })
}

export function ExceptionRequestInputSchema(): z.ZodObject<Properties<ExceptionRequestInput>> {
  return z.object({
    exceptionEnd: z.string().datetime().nullish(),
    exceptionSegment: z.lazy(() => ExceptionSegmentInputSchema()),
    exceptionStart: z.string().datetime(),
    exceptionType: RoadSegmentRestrictionCauseSchema
  })
}

export function ExceptionResponseSchema(): z.ZodObject<Properties<ExceptionResponse>> {
  return z.object({
    __typename: z.literal('ExceptionResponse').optional(),
    createdAt: z.string().datetime(),
    exceptionEnd: z.string().datetime().nullish(),
    exceptionSegment: ExceptionSegmentSchema(),
    exceptionStart: z.string().datetime(),
    exceptionType: RoadSegmentRestrictionCauseSchema,
    id: z.string().uuid().min(1),
    status: ExceptionRequestStatusSchema
  })
}

export function ExceptionSegmentSchema(): z.ZodObject<Properties<ExceptionSegment>> {
  return z.object({
    __typename: z.literal('ExceptionSegment').optional(),
    direction: RoadSegmentDirectionSchema,
    from: z.number(),
    to: z.number()
  })
}

export function ExceptionSegmentInputSchema(): z.ZodObject<Properties<ExceptionSegmentInput>> {
  return z.object({
    direction: RoadSegmentDirectionSchema,
    from: z.number(),
    to: z.number()
  })
}

export function GeneratedRouteSchema(): z.ZodObject<Properties<GeneratedRoute>> {
  return z.object({
    __typename: z.literal('GeneratedRoute').optional(),
    createdAt: z.string().datetime(),
    durationInSeconds: z.number(),
    externalRouteId: z.string().min(1),
    landingHub: HubSchema(),
    landingHubId: z.string().uuid().min(1),
    launchHub: HubSchema(),
    launchHubId: z.string().uuid().min(1),
    predefinedRouteId: z.string().uuid().min(1),
    totalDistanceInMeters: z.number(),
    updatedAt: z.string().datetime(),
    waypoints: z.array(PositionSchema())
  })
}

export function HealthStatusInformationSchema(): z.ZodObject<Properties<HealthStatusInformation>> {
  return z.object({
    __typename: z.literal('HealthStatusInformation').optional(),
    status: HealthStatusSchema
  })
}

export function HubSchema(): z.ZodObject<Properties<Hub>> {
  return z.object({
    __typename: z.literal('Hub').optional(),
    active: z.boolean(),
    address: z.string().nullish(),
    createdAt: z.string().datetime(),
    id: z.string().uuid().min(1),
    name: z.string().min(1),
    position: PositionSchema(),
    updatedAt: z.string().datetime()
  })
}

export function MRMStateSchema(): z.ZodObject<Properties<MRMState>> {
  return z.object({
    __typename: z.literal('MRMState').optional(),
    mrmStateValue: MRMStateValueSchema.nullish(),
    updatedAt: z.string().datetime()
  })
}

export function MRMStateInputSchema(): z.ZodObject<Properties<MRMStateInput>> {
  return z.object({
    mrmStateValue: MRMStateValueSchema.nullish(),
    updatedAt: z.string().datetime()
  })
}

export function MissionSchema(): z.ZodObject<Properties<Mission>> {
  return z.object({
    __typename: z.literal('Mission').optional(),
    actualArrivalTime: z.string().datetime().nullish(),
    actualStartTime: z.string().datetime().nullish(),
    createdAt: z.string().datetime(),
    description: z.string().nullish(),
    events: z.array(MissionEventSchema()).nullish(),
    id: z.string().uuid().min(1),
    name: z.string().min(1),
    plannedArrivalTime: z.string().datetime().nullish(),
    progressDetails: MissionProgressDetailsSchema().nullish(),
    route: GeneratedRouteSchema().nullish(),
    state: MissionStateSchema(),
    updatedAt: z.string().datetime(),
    vin: z.string().min(1)
  })
}

export function MissionEventSchema(): z.ZodObject<Properties<MissionEvent>> {
  return z.object({
    __typename: z.literal('MissionEvent').optional(),
    createdAt: z.string().datetime(),
    position: PositionSchema(),
    type: MissionEventTypeSchema
  })
}

export function MissionEventInputSchema(): z.ZodObject<Properties<MissionEventInput>> {
  return z.object({
    createdAt: z.string().datetime(),
    position: z.lazy(() => PositionInputSchema()),
    type: MissionEventTypeSchema
  })
}

export function MissionProgressDetailsSchema(): z.ZodObject<Properties<MissionProgressDetails>> {
  return z.object({
    __typename: z.literal('MissionProgressDetails').optional(),
    eta: z.string().datetime(),
    remainingDistanceInMeters: z.number(),
    traveledDistanceInMeters: z.number(),
    updatedAt: z.string().datetime()
  })
}

export function MissionProgressDetailsInputSchema(): z.ZodObject<Properties<MissionProgressDetailsInput>> {
  return z.object({
    eta: z.string().datetime(),
    remainingDistanceInMeters: z.number(),
    traveledDistanceInMeters: z.number(),
    updatedAt: z.string().datetime()
  })
}

export function MissionStateSchema(): z.ZodObject<Properties<MissionState>> {
  return z.object({
    __typename: z.literal('MissionState').optional(),
    updatedAt: z.string().datetime(),
    value: MissionStateValueSchema
  })
}

export function MissionStateInputSchema(): z.ZodObject<Properties<MissionStateInput>> {
  return z.object({
    updatedAt: z.string().datetime(),
    value: MissionStateValueSchema
  })
}

export function OwnerSchema(): z.ZodObject<Properties<Owner>> {
  return z.object({
    __typename: z.literal('Owner').optional(),
    email: z.string().min(1),
    fullName: z.string().min(1),
    id: z.string().uuid().min(1),
    phoneNumber: z.string().min(1),
    position: z.string().min(1)
  })
}

export function OwnerInputSchema(): z.ZodObject<Properties<OwnerInput>> {
  return z.object({
    email: z.string().min(1),
    fullName: z.string().min(1),
    id: z.string().uuid().min(1),
    phoneNumber: z.string().min(1),
    position: z.string().min(1)
  })
}

export function PositionSchema(): z.ZodObject<Properties<Position>> {
  return z.object({
    __typename: z.literal('Position').optional(),
    altitude: z.number().nullish(),
    heading: z.number().nullish(),
    latitude: z.number().nullish(),
    longitude: z.number().nullish()
  })
}

export function PositionInputSchema(): z.ZodObject<Properties<PositionInput>> {
  return z.object({
    altitude: z.number().nullish(),
    heading: z.number().nullish(),
    latitude: z.number().nullish(),
    longitude: z.number().nullish()
  })
}

export function ResetMissionInputSchema(): z.ZodObject<Properties<ResetMissionInput>> {
  return z.object({
    missionId: z.string().min(1)
  })
}

export function RoadSegmentSchema(): z.ZodObject<Properties<RoadSegment>> {
  return z.object({
    __typename: z.literal('RoadSegment').optional(),
    direction: RoadSegmentDirectionSchema,
    from: z.number(),
    to: z.number(),
    waypoints: z.array(PositionSchema())
  })
}

export function RoadSegmentInputSchema(): z.ZodObject<Properties<RoadSegmentInput>> {
  return z.object({
    direction: RoadSegmentDirectionSchema,
    from: z.number(),
    to: z.number(),
    waypoints: z.array(z.lazy(() => PositionInputSchema()))
  })
}

export function RoadSegmentStatusSchema(): z.ZodObject<Properties<RoadSegmentStatus>> {
  return z.object({
    __typename: z.literal('RoadSegmentStatus').optional(),
    createdAt: z.string().datetime(),
    id: z.string().min(1),
    reportedFromSourceAt: z.string().datetime(),
    restrictionCauses: z.array(RoadSegmentRestrictionCauseSchema),
    restrictionEnd: z.string().datetime().nullish(),
    restrictionStart: z.string().datetime(),
    segment: RoadSegmentSchema(),
    source: z.string().min(1),
    updatedAt: z.string().datetime()
  })
}

export function RouteSchema(): z.ZodObject<Properties<Route>> {
  return z.object({
    __typename: z.literal('Route').optional(),
    averageDuration: z.number(),
    createdAt: z.string().datetime(),
    distance: z.number(),
    id: z.string().uuid().min(1),
    landingHub: HubSchema(),
    launchHub: HubSchema(),
    updatedAt: z.string().datetime(),
    waypoints: z.array(PositionSchema())
  })
}

export function UpdateHubInputSchema(): z.ZodObject<Properties<UpdateHubInput>> {
  return z.object({
    active: z.boolean().nullish(),
    id: z.string().uuid().min(1),
    name: z.string().min(1),
    position: z.lazy(() => PositionInputSchema().nullish())
  })
}

export function UpdatedGeneratedRouteInputSchema(): z.ZodObject<Properties<UpdatedGeneratedRouteInput>> {
  return z.object({
    createdAt: z.string().datetime(),
    durationInSeconds: z.number(),
    externalRouteId: z.string().min(1),
    landingHubId: z.string().uuid().min(1),
    launchHubId: z.string().uuid().min(1),
    predefinedRouteId: z.string().uuid().min(1),
    totalDistanceInMeters: z.number(),
    updatedAt: z.string().datetime(),
    waypoints: z.array(z.lazy(() => PositionInputSchema()))
  })
}

export function UpdatedMissionInputSchema(): z.ZodObject<Properties<UpdatedMissionInput>> {
  return z.object({
    actualArrivalTime: z.string().datetime().nullish(),
    actualStartTime: z.string().datetime().nullish(),
    createdAt: z.string().datetime(),
    description: z.string().nullish(),
    events: z.array(z.lazy(() => MissionEventInputSchema())).nullish(),
    id: z.string().uuid().min(1),
    name: z.string().min(1),
    plannedArrivalTime: z.string().datetime().nullish(),
    progressDetails: z.lazy(() => MissionProgressDetailsInputSchema().nullish()),
    route: z.lazy(() => UpdatedGeneratedRouteInputSchema().nullish()),
    state: z.lazy(() => MissionStateInputSchema()),
    updatedAt: z.string().datetime(),
    vin: z.string().min(1)
  })
}

export function UpdatedRoadSegmentStatusInputSchema(): z.ZodObject<Properties<UpdatedRoadSegmentStatusInput>> {
  return z.object({
    createdAt: z.string().datetime(),
    id: z.string().min(1),
    reportedFromSourceAt: z.string().datetime(),
    restrictionCauses: z.array(RoadSegmentRestrictionCauseSchema),
    restrictionEnd: z.string().datetime().nullish(),
    restrictionStart: z.string().datetime(),
    segment: z.lazy(() => RoadSegmentInputSchema()),
    source: z.string().min(1),
    updatedAt: z.string().datetime()
  })
}

export function UpdatedVehicleInputSchema(): z.ZodObject<Properties<UpdatedVehicleInput>> {
  return z.object({
    activeMissionId: z.string().uuid().nullish(),
    adsState: AdsStateSchema,
    cameras: z.array(z.lazy(() => CameraInputSchema())),
    connectivityStatus: z.lazy(() => ConnectivityStatusInputSchema().nullish()),
    createdAt: z.string().datetime(),
    customerId: z.string().uuid().nullish(),
    deviations: z.array(z.lazy(() => DeviationInputSchema())),
    drivingState: DrivingStateSchema,
    externalId: z.string().uuid().min(1),
    latestVehicleUpdate: z.string().datetime().nullish(),
    licensePlate: z.string().nullish(),
    mrmState: z.lazy(() => MRMStateInputSchema().nullish()),
    name: z.string().nullish(),
    owners: z.array(z.lazy(() => OwnerInputSchema())),
    position: z.lazy(() => PositionInputSchema().nullish()),
    speedInKmh: z.number().nullish(),
    speedInMs: z.number().nullish(),
    updatedAt: z.string().datetime(),
    vin: z.string().min(1),
    weightInKg: z.number().nullish()
  })
}

export function UpdatedVideoStreamingErrorInputSchema(): z.ZodObject<Properties<UpdatedVideoStreamingErrorInput>> {
  return z.object({
    cameraID: z.string().nullish(),
    errorCode: VideoStreamingErrorCodeSchema,
    errorMessage: z.string().nullish(),
    vin: z.string().min(1)
  })
}

export function VehicleSchema(): z.ZodObject<Properties<Vehicle>> {
  return z.object({
    __typename: z.literal('Vehicle').optional(),
    activeMissionId: z.string().uuid().nullish(),
    adsState: AdsStateSchema,
    cameras: z.array(CameraSchema()),
    connectivityStatus: ConnectivityStatusSchema().nullish(),
    createdAt: z.string().datetime(),
    customerId: z.string().uuid().nullish(),
    deviations: z.array(DeviationSchema()),
    drivingState: DrivingStateSchema,
    externalId: z.string().uuid().min(1),
    latestVehicleUpdate: z.string().datetime().nullish(),
    licensePlate: z.string().nullish(),
    mrmState: MRMStateSchema().nullish(),
    name: z.string().nullish(),
    owners: z.array(OwnerSchema()),
    position: PositionSchema().nullish(),
    speedInKmh: z.number().nullish(),
    speedInMs: z.number().nullish(),
    updatedAt: z.string().datetime(),
    vin: z.string().min(1),
    weightInKg: z.number().nullish()
  })
}

export function VehicleCommandInputSchema(): z.ZodObject<Properties<VehicleCommandInput>> {
  return z.object({
    command: CommandSchema,
    vin: z.string().min(1)
  })
}

export function VideoStreamSchema(): z.ZodObject<Properties<VideoStream>> {
  return z.object({
    __typename: z.literal('VideoStream').optional(),
    cameraID: z.string().nullish(),
    streamingURL: z.string().min(1),
    vin: z.string().min(1)
  })
}

export function VideoStreamingErrorSchema(): z.ZodObject<Properties<VideoStreamingError>> {
  return z.object({
    __typename: z.literal('VideoStreamingError').optional(),
    cameraID: z.string().nullish(),
    errorCode: VideoStreamingErrorCodeSchema,
    errorMessage: z.string().nullish(),
    vin: z.string().min(1)
  })
}
