import { useEffect, useState } from 'react'
import { fetchAuthSession } from 'aws-amplify/auth'
import Button from 'components/actions/Button'
import ErrorMessage from './ErrorMessage'
import Fileinput from './FileInput'
import FilePreview from './FilePreview'

interface UploadScenarioFormProps {
  domain: string | undefined
}
/**
 * TODOs:
 * - implement VIN validation
 * - disable Upload Scenario button
 */
const UploadScenarioForm = ({ domain }: UploadScenarioFormProps) => {
  const [inputVIN, setInputVIN] = useState<string | undefined>(undefined)
  const [inputCustomerId, setInputCustomerId] = useState<string | undefined>(undefined)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [fileContent, setFileContent] = useState<string | undefined>()
  // request flow
  const [isRequestSending, setIsRequestSending] = useState<boolean>(false)
  const [isRequestFailed, setIsRequestFailed] = useState<boolean>(false)

  const closeErrorMessage = () => {
    setIsRequestFailed(false)
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) {
      return
    }
    const fileInput = files[0]
    setFile(fileInput)
  }

  const resetFile = () => {
    setFile(undefined)
    setFileContent(undefined)
  }

  useEffect(() => {
    const readFile = () => {
      const fileReader = new FileReader()
      if (file) {
        fileReader.readAsText(file, 'UTF-8')
        fileReader.onload = (readerEvent) => {
          if (readerEvent?.target?.result) {
            setFileContent(readerEvent.target.result.toString())
          }
        }
      }
    }
    readFile()
  }, [file])

  const uploadSimulation = async () => {
    const url = new URL(`${domain}/simulator-adapter-service/api/v1/simulation/upload/${inputVIN}?customerId=${inputCustomerId}`)
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}

    try {
      if (file && inputCustomerId) {
        const formData = new FormData()
        formData.append('file', file)

        await fetch(url, {
          method: 'POST',
          mode: 'cors',
          body: formData,
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': 'Content-Type',
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
        })

        setIsRequestFailed(false)
        setIsRequestSending(true)

        console.info('[Simulation] File to be uploaded', file)
      }
    } catch (error) {
      setIsRequestFailed(true)
      console.error(error)
    } finally {
      setIsRequestSending(false)
    }
  }

  return (
    <div className="flex flex-col items-center">
      {isRequestFailed && (
        <ErrorMessage message="Error while uploading scenario." onClose={closeErrorMessage} />
      )}
      <div
        className="form-group flex w-full flex-wrap items-center gap-10"
      >
        <input
          onChange={(e) => setInputVIN(e.currentTarget.value)}
          type="text"
          className="form-control w-[170px]"
          id="inputVIN"
          placeholder="Vehicle VIN"
        />
        <input
          onChange={(e) => setInputCustomerId(e.currentTarget.value)}
          type="text"
          className="form-control w-[170px]"
          id="inputCustomerId"
          placeholder="Customer Id"
        />
        <Button
          data-testid="upload-simulation-scenario-button"
          disabled={!inputVIN || !inputCustomerId || !file || isRequestSending}
          className="btn btn-primary"
          onClick={uploadSimulation}
        >
          <div className="flex items-center p-10">
            {/*
              Actual implementation: the simulation is triggered as soon the scenario is uploaded. - check with BE
              Change button label and button icon.

              <span className="rioglyph rioglyph-upload mr-5 text-16" />
              <p>Upload scenarion</p>
            */}
            <span className="rioglyph rioglyph-play mr-5 text-16" />
            <p>Start simulation</p>
          </div>
        </Button>
      </div>
      <Fileinput file={file} onChange={handleFile} resetFile={resetFile} />
      <FilePreview file={file} content={fileContent} />
    </div>
  )
}

export default UploadScenarioForm
