import IconButton from 'components/actions/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedVIN, setSelectedCluster } from 'store/slices/appSlice'
import ClusterDetailsVehicleItem from './ClusterDetailsVehicleItem'
import { Hub, Severity, Vehicle } from 'API'
import ClusterDetailsHubItem from './ClusterDetailsHubItem'
import { prioritizeDeviations, sortBySeverity } from '../prioritized-deviations/utils'
import { denormalizeListWithVin } from '../../services/utils'
import { useMemo } from 'react'
import { isHub, isVehicle } from './utils'

interface ClusterDetailsProps {
  cluster?: (Vehicle | Hub)[]
  showHubs: boolean
}

const ClusterDetails = ({ cluster, showHubs }: ClusterDetailsProps) => {
  const selectedVIN = useSelector(getSelectedVIN)
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(setSelectedCluster([]))
  }

  const vehicles = useMemo(() => cluster?.filter(isVehicle) || [], [cluster])
  const hubs = cluster?.filter(isHub)

  const vehiclesSortedBySeverity: (Vehicle & { severity?: Severity })[] = useMemo(() => {
    return (vehicles || [])
      .map((vehicle) => {
        const deviations = prioritizeDeviations(denormalizeListWithVin([vehicle]))
        const severity = !!deviations?.errors.length
          ? Severity.ERROR
          : !!deviations?.warnings.length
            ? Severity.WARNING
            : undefined
        return { ...vehicle, severity }
      })
      .sort(sortBySeverity)
  }, [vehicles])

  return (
    <div
      className={`${!!cluster?.length ? 'opacity-100' : 'opacity-0'} relative bg-white shadow-sm transition-opacity ease-in-out`}
    >
      <div>
        <div className="sticky top-0 z-20 flex items-center justify-between bg-white p-15">
          <p className="text-16 font-bold">Cluster details</p>
          <IconButton
            className="hover:cursor-pointer"
            onClick={handleClose}
            size="xs"
            style="muted"
          >
            <span className="rioglyph rioglyph-remove" />
          </IconButton>
        </div>
        <div className="p-15 pt-0">
          <ul>
            {vehiclesSortedBySeverity.map(({ severity, ...vehicle }) => (
              <li key={vehicle.vin} className="mb-10 flex items-center justify-between">
                <ClusterDetailsVehicleItem
                  vehicle={vehicle}
                  severity={severity}
                  isSelected={vehicle.vin === selectedVIN}
                />
              </li>
            ))}
          </ul>
          {showHubs && !!hubs?.length && (
            <ul>
              {hubs?.map((hub) => (
                <li key={hub.id} className="mb-10 flex items-center justify-between">
                  <ClusterDetailsHubItem hub={hub} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default ClusterDetails
