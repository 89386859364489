import { SingleMapMarker } from 'components/map/Map'
import { Hub } from 'API'

interface HubMarkerProps {
  hub: Hub
}

const HubComponent = ({ hub }: HubMarkerProps) => (
  <SingleMapMarker
    clickable={false}
    name={hub.name}
    iconNames={['factory']}
    markerColor="bg-map-marker-poi"
  />
)

export default HubComponent
