import { MarkerColor } from '@rio-cloud/rio-uikit/mapTypes'
import { Severity } from 'API'
import { useMemo } from 'react'

export const useMarkerStyles = (severity?: Severity, isSelected?: boolean, hubsOnly?: boolean) => {
  const background: MarkerColor = useMemo(
    () =>
      hubsOnly
        ? 'bg-map-marker-poi'
        : ({
            [Severity.ERROR]: 'bg-map-marker-danger',
            [Severity.WARNING]: 'bg-map-marker-warning',
            default: 'bg-map-marker-asset'
          }[severity || 'default'] as MarkerColor),
    [severity, hubsOnly]
  )

  const backgroundWithSelection: MarkerColor | 'bg-white' = useMemo(
    () => (isSelected ? 'bg-white' : background),
    [isSelected, background]
  )

  const foreground = useMemo(
    () =>
      hubsOnly
        ? 'text-map-marker-poi'
        : {
            [Severity.ERROR]: 'text-danger',
            [Severity.WARNING]: 'text-warning',
            default: 'text-black'
          }[severity || 'default'],
    [severity, hubsOnly]
  )

  const foregroundWithSelection = useMemo(
    () => (!isSelected ? 'text-white' : foreground),
    [isSelected, foreground]
  )

  const border = useMemo(
    () =>
      hubsOnly
        ? 'border-2 border-map-marker-poi'
        : {
            [Severity.ERROR]: 'border-2 border-danger',
            [Severity.WARNING]: 'border-2 border-warning',
            default: 'border-2 border-map-marker-asset'
          }[severity || 'default'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [severity, isSelected]
  )

  return {
    background,
    foreground,
    backgroundWithSelection,
    foregroundWithSelection,
    border,
    classNamesIgnoringSelection: [background, foreground, border].join(' '),
    classNamesWithSelection: [backgroundWithSelection, foregroundWithSelection, border].join(' ')
  }
}
