import { formatTime } from 'helper/time'

interface ClockProps {
  datetime: Date
}
const Clock = ({ datetime }: ClockProps) => {
  return (
    <div
      data-testid="clock"
      className="flex w-fit items-center gap-10 bg-white p-10 text-left font-body text-14 font-medium text-gray-darkest shadow-sm"
    >
      <span className="rioglyph rioglyph-time text-[24px] text-primary" />
      <p className="w-[59px]">{formatTime(datetime, 'HH:mm:ss')}</p>
    </div>
  )
}

export default Clock
