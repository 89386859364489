import RIOButton, { BUTTON_SIZE, BUTTON_STYLE, BUTTON_VARIANT } from '@rio-cloud/rio-uikit/Button'
import { PropsWithChildren } from 'react'
import { PropsWithTestId } from '../types'

interface IconButtonProps {
  onClick?: () => void
  variant?: BUTTON_VARIANT
  size?: BUTTON_SIZE
  style?: BUTTON_STYLE
  disabled?: boolean
  className?: string
  type?: 'button' | 'submit'
  dark?: boolean
  'data-testid'?: string
}

/**
 * The IconButton component is used for actions or links in a page. It only displays an icon and no additional label.
 * It's a wrapper around the Button component from rio-uikit
 */
const IconButton = ({
  onClick,
  variant,
  size,
  style,
  className,
  disabled,
  type,
  dark,
  children,
  'data-testid': dataTestId
}: PropsWithTestId<PropsWithChildren<IconButtonProps>>) => {
  return (
    <RIOButton
      data-testid={dataTestId}
      onClick={onClick}
      variant={variant}
      bsSize={size}
      bsStyle={style}
      disabled={disabled}
      className={`btn-icon-only ${className} ${dark && 'border-none bg-gray-darker text-gray-light outline-none hover:bg-gray-darkest active:bg-black'}`}
      type={type}
    >
      {children}
    </RIOButton>
  )
}

export default IconButton
