import MainNavigation from 'components/navigation/MainNavigation'
import AccountMenu from './AccountMenu'

interface AppHeaderProps {
  title: string | React.ReactNode
  showAccountMenu?: boolean
}

const AppHeader = ({ title, showAccountMenu = false }: AppHeaderProps) => {
  // ACC-306 we do not use actual tab navigation but use it as title
  // Note that this is not clean usage of the RIO component + there is some responsive behavior for mobile breakpoints.
  const navItems = [
    {
      key: 'Map',
      route: <p className="font-display text-14 font-semibold uppercase">{title}</p>
    }
  ]

  const actionBarItems = showAccountMenu ? [<AccountMenu key={'account'} />] : []

  return <MainNavigation showHomeIcon={false} navItems={navItems} actionBarItems={actionBarItems} />
}

export default AppHeader
