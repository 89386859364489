import { useEffect, useState } from 'react'
import Button from 'components/actions/Button'
import ErrorMessage from './ErrorMessage'

interface UploadScenarioFormProps {
  domain: string | undefined
}
/**
 * Fot future implementations:
 * - how to validate vin - and disable Upload Scenario button
 */
const UploadScenarioForm = ({ domain }: UploadScenarioFormProps) => {
  const [inputVIN, setInputVIN] = useState<string | undefined>(undefined)
  const [inputCustomerId, setInputCustomerId] = useState<string | undefined>(undefined)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [preview, setPreview] = useState<string | undefined>()
  // request flow
  const [isRequestSending, setIsRequestSending] = useState<boolean>(false)
  const [isRequestFailed, setIsRequestFailed] = useState<boolean>(false)

  const closeErrorMessage = () => {
    setIsRequestFailed(false)
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) {
      return
    }
    const fileInput = files[0]
    setFile(fileInput)
  }

  const resetFile = () => {
    setFile(undefined)
    setPreview(undefined)
  }

  useEffect(() => {
    const readFile = () => {
      const fileReader = new FileReader()
      if (file) {
        fileReader.readAsText(file, 'UTF-8')
        fileReader.onload = (readerEvent) => {
          if (readerEvent?.target?.result) {
            setPreview(readerEvent.target.result.toString())
          }
        }
      }
    }

    readFile()
  }, [file])

  const uploadSimulation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const url = `${domain}/simulator-adapter-service/api/v1/simulation/upload/${inputVIN}?customerId=${inputCustomerId}`
    try {
      console.info('[Simulation] File to be uploaded', file)
      await fetch(url, { method: 'POST', body: file })
      setIsRequestFailed(false)
      setIsRequestSending(true)
    } catch (error) {
      setIsRequestFailed(true)
      console.error(error)
    } finally {
      setIsRequestSending(false)
    }
  }

  return (
    <div className="flex flex-col items-center">
      {isRequestFailed && (
        <ErrorMessage message="Error while uploading scenario." onClose={closeErrorMessage} />
      )}
      <form
        className="form-group flex w-full flex-wrap items-center gap-10"
        onSubmit={uploadSimulation}
      >
        <input
          onChange={(e) => setInputVIN(e.currentTarget.value)}
          type="text"
          className="form-control w-[170px]"
          id="inputVIN"
          placeholder="Vehicle VIN"
        />
        <input
          onChange={(e) => setInputCustomerId(e.currentTarget.value)}
          type="text"
          className="form-control w-[170px]"
          id="inputCustomerId"
          placeholder="Customer Id"
        />
        <Button
          data-testid="upload-simulation-scenario-button"
          disabled={!inputVIN || !inputCustomerId || !file || isRequestSending}
          className="btn btn-primary"
          type="submit"
        >
          <div className="flex items-center p-10">
            {/*
              Actual implementation: the simulation is triggered as soon the scenario is uploaded.
              Change button label and button icon.

              <span className="rioglyph rioglyph-upload mr-5 text-16" />
              <p>Upload scenarion</p>
            */}
            <span className="rioglyph rioglyph-play mr-5 text-16" />
            <p>Start simulation</p>
          </div>
        </Button>
      </form>
      <div className="flex w-full justify-start">
        <div className="max-w-[240px]">
          {!file ? (
            <input
              className="max-w-[200px] cursor-pointer"
              type="file"
              onChange={handleFile}
              accept="application/json"
            />
          ) : (
            <p className="">
              {file.name}
              <span
                onClick={resetFile}
                className="rioglyph rioglyph-remove mx-5 cursor-pointer text-16 text-danger"
              />
            </p>
          )}
        </div>
      </div>
      <div
        className={`mt-15 h-[300px] w-full overflow-y-auto bg-gray-lighter p-20 ${!file && 'flex items-center justify-center'}`}
      >
        <div className="">{preview ?? 'No file to preview'}</div>
      </div>
    </div>
  )
}

export default UploadScenarioForm
