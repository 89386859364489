import { memo } from 'react'
import { isDeepEqual } from 'remeda'

interface SystemNotificationProps {
  shouldRender: boolean
}

const SystemNotification = () => {
  return (
    <div className="flex w-full animate-translate-down items-center bg-[#FDF3F3] px-15 py-10">
      <span className="rioglyph rioglyph-error-sign text-[25px] text-danger" />
      <p className="ml-10 font-body text-14 text-danger">
        <b>We&apos;re currently experiencing technical issues.</b> Some functionalities may be
        affected.
      </p>
    </div>
  )
}

const arePropsEqual = (prevProps: SystemNotificationProps, nextProps: SystemNotificationProps) =>
  isDeepEqual(prevProps.shouldRender, nextProps.shouldRender)

export default memo(SystemNotification, arePropsEqual)
